import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@sista/dista-ui";
import { i18nextConfigure, useLogoProps } from "@sista/fe-commons";
import { tryLocalStorageLogin } from "@sista/idm-client";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./i18n";

i18nextConfigure({
  currentAppNamespace: "ucet", // replace with your app namespace (filename in Localazy without the .json extension)
  debug: false, //process.env.NODE_ENV === "development",
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById("root")!);

const EmptyContent = () => {
  const logoProps = useLogoProps();

  return <ThemeProvider theme={theme}>{""}</ThemeProvider>;
};

root.render(<EmptyContent />);

tryLocalStorageLogin(true, process.env.REACT_APP_IDM_ROOT || "/idm", true).then(
  (account) => {
    //console.debug("Logged account", account);
    root.render(
      <React.StrictMode>
        <App account={account} />
      </React.StrictMode>
    );
  }
);
