import { Box } from "@mui/material";
import { Organization } from "@sista/library-stasi";
import { ProjectList } from "@sista/service-project-skeleton";

export type OrganizationProjectsContentProps = {
  organization: Organization;
};

export const OrganizationProjectsContent = (
  props: OrganizationProjectsContentProps
) => {
  return (
    <Box sx={{ mt: 2 }}>
      <ProjectList
        filter={{
          entityType: "organizations",
          entityId: props.organization.id,
        }}
      />
    </Box>
  );
};
