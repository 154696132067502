import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import { AlertsContext, DistaDialog, DistaDialogHeader } from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import { Profile, SmartButton, useSafeSistaAsync } from "@sista/library-stasi";
import { useEditorDialogHandle } from "@sista/library-stasi/dist/hooks/useEditorDialogHandle";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProfileContactForm } from "./ProfileContactForm";
import { useRejstrikClient } from "../../api/utils/useRejstrikClient";
import { nvl } from "../../utils/nvl";
import { useProfileNames } from "../../utils/useProfileNames";

type ProfileCardProps = {
  profile: Profile;
  editable?: boolean;
  onProfileSelected?: (profile: Profile) => void;
};

export const ProfileCard = (props: ProfileCardProps) => {
  const a = useContext(AlertsContext);
  const { loggedAccount, switchProfile, refresh } =
    useContext(IdmClientContext);
  const rej = useRejstrikClient();
  const profileName = useProfileNames();

  const { t } = useTranslation("ucet");

  const [showDisableConfimDialog, setShowDisableConfimDialog] = useState(false);

  const [updateProfileCall, updateProfileState] = useSafeSistaAsync(
    rej.updateProfile
  );
  const [updateProfileByOwnerCall, updateProfileByOwnerState] =
    useSafeSistaAsync(rej.updateProfileByOwner);

  const hasMoreProfiles = loggedAccount.profiles.length > 1;

  const closeDisableDialogHandler = () => {
    setShowDisableConfimDialog(false);
  };

  const getProfileData = useCallback(
    () =>
      rej.findProfileByIdByDate({
        profileId: props.profile!.id,
      }),
    [rej, props.profile]
  );

  const asOwner = props.profile.personId === loggedAccount.personId;
  const isActive = props.profile.id === loggedAccount.activeProfileId;

  const saveProfileData = useCallback(
    (d: Profile) =>
      asOwner
        ? rej.updateProfileByOwner({
            profileId: props.profile!.id,
            changeReason: "edit from ui by owner",
            minorChange: false,
            data: d,
          })
        : rej.updateProfile({
            profileId: props.profile!.id,
            changeReason: "edit from ui by admin",
            minorChange: false,
            data: d,
          }),
    [props.profile, rej, asOwner]
  );

  const disableProfile = useCallback(async () => {
    setShowDisableConfimDialog(false);
    const result = asOwner
      ? await updateProfileByOwnerCall({
          profileId: props.profile!.id,
          changeReason: "disable from ui by owner",
          minorChange: false,
          data: { ...props.profile, dateDisabled: new Date().toISOString() },
        })
      : await updateProfileCall({
          profileId: props.profile!.id,
          changeReason: "disable from ui by admin",
          minorChange: false,
          data: { ...props.profile, dateDisabled: new Date().toISOString() },
        });
    if (typeof result === "object" && result) {
      a.showSuccess(
        t("ucet.profile.disable.success", "Profil byl deaktivován")
      );
      refresh();
    }
  }, [
    props.profile,
    updateProfileCall,
    updateProfileByOwnerCall,
    asOwner,
    a,
    refresh,
    t,
  ]);

  const { openedEditorDialog, openEditorDialog } = useEditorDialogHandle({
    getData: getProfileData,
    saveData: saveProfileData,
    editor: ProfileContactForm,
    refresh: refresh,
  });

  const doSwitch = () => {
    props.onProfileSelected?.(props.profile);
  };

  const limitedEdit =
    props.profile.type == "PROVIDER" || props.profile.type == "GESTOR";

  const editableType = (asOwner && !limitedEdit) || !asOwner;

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={0}>
            <Typography variant="h3">
              {profileName(props.profile.type)}&nbsp;
              {isActive && (
                <Chip label={t("ucet.profile.active", "aktivní profil")} />
              )}
              {props.profile.dateDisabled && (
                <Chip
                  label={t("ucet.profile.disabled", "deaktivovaný profil")}
                  color="error"
                  size="small"
                />
              )}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            <Typography variant="body1" sx={{ color: "#ddd" }}>
              {props.profile.id}
            </Typography>
          </Stack>
          <Typography variant="body1">
            {t("ucet.settings.contactEmail", "e-mail:")}
            {nvl(props.profile.email)},&nbsp;
            {t("ucet.settings.contactPhone", "tel:")}
            {nvl(props.profile.phone)}
          </Typography>
          {props.profile.type != "EXPERT" && (
            <Typography variant="body1">
              {nvl(props.profile.caption)}
            </Typography>
          )}
          {showDisableConfimDialog && (
            <DistaDialog
              open={showDisableConfimDialog}
              title={t("ucet.profile.disable.title", "Deaktivace profilu")}
              onClose={closeDisableDialogHandler}
            >
              <DistaDialogHeader
                title={t("ucet.profile.disable.title", "Deaktivace profilu")}
                onClose={closeDisableDialogHandler}
              />
              <DialogContent>
                <Typography sx={{ mb: 2 }}>
                  {t(
                    "ucet.profile.disable.confirm.question",
                    "Opravdu chcete deaktivovat tento profil?"
                  )}
                </Typography>
                <Stack direction={"row"} spacing={2}>
                  <Button
                    onClick={closeDisableDialogHandler}
                    variant="outlined"
                    color="primary"
                  >
                    {t("common.cancel", "Zrušit")}
                  </Button>
                  <Box sx={{ flexGrow: 1 }} />
                  <Button
                    onClick={disableProfile}
                    variant="contained"
                    color="error"
                  >
                    {t(
                      "ucet.profile.disable.confirm.yes",
                      "Deaktivovat profil"
                    )}
                  </Button>
                </Stack>
              </DialogContent>
            </DistaDialog>
          )}
        </CardContent>
        <CardActions>
          {openedEditorDialog}
          {props.editable == true &&
            editableType &&
            props.profile.dateDisabled == null && (
              <Stack direction={"row"} spacing={2}>
                {props.profile.type === "APPLICANT" && (
                  <>
                    <Button
                      onClick={() => {
                        return setShowDisableConfimDialog(true);
                      }}
                      variant="text"
                      color="error"
                    >
                      {t("ucet.settings.disableProfile", "Deaktivovat profil")}
                    </Button>
                    <Box sx={{ flexGrow: 1 }} />
                  </>
                )}
                <Button onClick={openEditorDialog} variant="outlined">
                  {t(
                    "ucet.settings.editContactswitch",
                    "Upravit kontaktní údaje"
                  )}
                </Button>
              </Stack>
            )}
          {/*{hasMoreProfiles && props.onProfileSelected && (*/}
          {/*  <Button variant="contained" onClick={doSwitch} disabled={isActive}>*/}
          {/*    {t("ucet.profile.switch", "Přepnout se na profil")}*/}
          {/*  </Button>*/}
          {/*)}*/}
        </CardActions>
      </Card>
    </>
  );
};
