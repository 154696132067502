import { Tab, Tabs } from "@mui/material";
import { Organization } from "@sista/library-stasi";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { featureZobrazeniProjektu } from "./../../utils/features";

type OrganizationDetailTabsProps = {
  organization: Organization;
};

export const OrganizationDetailTabs = (props: OrganizationDetailTabsProps) => {
  const view = useParams().view ?? "view";
  const navigate = useNavigate();
  const { t } = useTranslation("ucet");

  const switchView = useCallback(
    (e: any, v: any) => {
      navigate("/sprava-organizace/" + props.organization.id + "/" + v);
    },
    [navigate, props.organization.id]
  );

  return (
    <Tabs onChange={switchView} value={view} variant="standard">
      <Tab label={t("ucet.tabs.view", "Základní informace")} value="view" />
      {!props.organization.parentId && (
        <Tab
          label={t("ucet.tabs.propertyTies", "Majetkové vazby")}
          value="propertyTies"
        />
      )}
      {!props.organization.parentId && (
        <Tab
          label={t("ucet.tabs.units", "Organizační jednotky")}
          value="units"
        />
      )}
      {featureZobrazeniProjektu && !props.organization.parentId && (
        <Tab label={t("ucet.tabs.projects", "Projekty")} value="projects" />
      )}
      <Tab label={t("ucet.tabs.users", "Uživatelé")} value="users" />
    </Tabs>
  );
};
