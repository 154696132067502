import { Box, CircularProgress } from "@mui/material";
import { isInOauthMode } from "@sista/idm-client";
import { useEffect } from "react";
// import { Beta3WidgetDefaults } from "@sista/service-needs";

export const RedirectToDashboardScreen = () => {
  // redirect to: /dashboard/

  useEffect(() => {
    const delay = isInOauthMode() ? 2000 : 200;
    setTimeout(() => {
      window.location.href = "/dashboard/";
    }, delay);
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <CircularProgress />
    </Box>
  );
};
