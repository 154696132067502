import { Box, Checkbox, Stack, Typography } from "@mui/material";
import {
  AlertsContext,
  DistaContent,
  DistaPageHeaderProps,
} from "@sista/dista-ui";
import { SistaPageLayout } from "@sista/fe-commons";
import { IdmClientContext } from "@sista/idm-client";
import {
  LoadingGuard,
  Right,
  SmartButton,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import { SistaChipWithDefaults } from "@sista/sista-chip";
import { useCallback, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useRejstrikClient } from "../../api/utils/useRejstrikClient";
import { useLocalNavigation } from "../../hooks/useLocalNavigation";

export const IstaMigrationScreen = () => {
  const rej = useRejstrikClient();
  const { loggedAccount } = useContext(IdmClientContext);
  const { showError, showSuccess } = useContext(AlertsContext);
  const { t } = useTranslation("ucet");

  const [selectedProjects, setSelectedProjects] = useState([] as string[]);

  const [istaMigrationListCall, istaMigrationListState] = useSafeSistaAsync(
    rej.istaMigrationList
  );

  const saveMigration = useCallback(async () => {
    const toSend = selectedProjects.filter((projectId) => {
      return (
        istaMigrationListState.value?.find(
          (project) =>
            project.projectId === projectId && project.canMigrate == true
        ) != null
      );
    });
    console.log("Will migrate", toSend);
    await rej.istaMigrationPerform(toSend);
    showSuccess(
      t("person.detail.ista.migration.success", "Migrace proběhla úspěšně")
    );
    setSelectedProjects([]);
    istaMigrationListCall({});
    return true;
  }, [
    istaMigrationListCall,
    istaMigrationListState.value,
    rej,
    selectedProjects,
    showSuccess,
    t,
  ]);

  const [saveMigrationCall, saveMigrationState] =
    useSafeSistaAsync(saveMigration);

  const toggleProject = useCallback((projectId: string) => {
    setSelectedProjects((prev) =>
      prev.includes(projectId)
        ? prev.filter((id) => id !== projectId)
        : [...prev, projectId]
    );
  }, []);

  const isProjectSelected = useCallback(
    (projectId: string) => {
      return selectedProjects.includes(projectId);
    },
    [selectedProjects]
  );

  useEffect(() => {
    istaMigrationListCall({});
  }, [istaMigrationListCall]);

  const headerConfig: DistaPageHeaderProps = {
    breadcrumbs: [
      {
        title: t("ucet.menu.breadcrumbs.home", "Domů"),
        action: () => (window.location.href = "/ucet/"),
      },
      {
        title: t("person.detail.ista.migration.title", "Migrace ISTA projektů"),
      },
    ],
    pageTitle: t("person.detail.ista.migration.title", "Migrace ISTA projektů"),
    chipsDefinition: [
      //{label: t('chips.approved', 'Approved'), color: 'primary'},
    ],
    actions: [
      // {
      //     title: t('mainpage.actions.primary', 'Primary action'),
      //     action: () => alert('Primary action clicked'),
      //     primary: true,
      // },
      // {title: t('mainpage.actions.action1', 'First action'), action: () => alert('First action clicked')},
      // {title: t('mainpage.actions.action2', 'Second action'), action: () => alert('Second action clicked')},
    ],
  };

  const localNavigationConfig = useLocalNavigation();

  return (
    <SistaPageLayout localNavigationConfig={localNavigationConfig}>
      <DistaContent headerConfig={headerConfig} fullWidth={false}>
        <Stack direction={"column"} sx={{ p: 2 }}>
          <LoadingGuard state={istaMigrationListState}>
            {(istaMigrationListState.value?.length ?? 0) > 0 ? (
              <>
                <Typography variant={"body1"}>
                  <Trans i18nKey={"person.detail.ista.migration.description"}>
                    <p>
                      V následujícím seznamu najdete projekty, které jsou
                      spojené s vaším ISTA účtem a které je možné přesunout pod
                      váš SISTA účet. Projekty se připojí pod váš aktuální
                      profil (tj. organizaci).
                    </p>
                    <p>
                      Pokud jste se ISTA projektů účastnil(a) pod různými
                      organizacemi, je nutné pro každou takovou organizaci
                      &nbsp;
                      <a href="/ucet/settings/profiles">
                        založit samostatný profil
                      </a>{" "}
                      &nbsp; a tento profil propojit s příslušnou organizací.
                    </p>
                    <p>
                      Projekty, které nelze označit, byly buď již zmigrovány
                      anebo patří organizaci, která není spojená s vaším
                      aktuálním SISTA profilem. Pak je nutné se buď na jiný
                      profil přepnout, případně ho založit.
                    </p>
                  </Trans>
                </Typography>
                {istaMigrationListState.value.map((project) => (
                  <Box key={project.projectId} sx={{ mb: 2 }}>
                    <Checkbox
                      checked={isProjectSelected(project.projectId)}
                      onChange={() => toggleProject(project.projectId)}
                      disabled={project.canMigrate !== true}
                    />
                    <SistaChipWithDefaults
                      urn={"urn:sista:projects:" + project.projectId}
                      variant="large"
                    />
                  </Box>
                ))}
                <Right>
                  <SmartButton
                    state={saveMigrationState}
                    onClick={saveMigrationCall}
                    disabled={selectedProjects.length <= 0}
                  >
                    {t(
                      "person.detail.ista.migration.save",
                      "Migrovat označené projekty"
                    )}
                  </SmartButton>
                </Right>
              </>
            ) : (
              <Typography variant={"body1"}>
                {t(
                  "person.detail.ista.migration.noProjects",
                  "Nebyly nalezeny žádné projekty vhodné k migraci."
                )}
              </Typography>
            )}
          </LoadingGuard>
        </Stack>
      </DistaContent>
    </SistaPageLayout>
  );
};
