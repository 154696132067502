import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  AlertsContext,
  DistaContent,
  DistaPageHeaderProps,
} from "@sista/dista-ui";
import { SistaPageLayout } from "@sista/fe-commons";
import { IdmClientContext, Profile, ProfileType } from "@sista/idm-client";
import {
  UiContext,
  useActiveMenuItem,
  useSafeSistaAsync,
} from "@sista/library-stasi";
import React, { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useIdmClient } from "../../api/utils/useIdmClient";
import { useRejstrikClient } from "../../api/utils/useRejstrikClient";
import { useLocalNavigation } from "../../hooks/useLocalNavigation";
import { useProfileNames } from "../../utils/useProfileNames";
import { ProfileCard } from "../ui/ProfileCard";

export const ProfilesSettingsScreen = (props: {
  registerOrganization: boolean;
}) => {
  const rej = useRejstrikClient();
  const { loggedAccount, switchProfile, refresh } =
    useContext(IdmClientContext);
  const { confirm, openDialog } = useContext(UiContext);
  const { showSuccess } = useContext(AlertsContext);
  const { t } = useTranslation("ucet");
  const profileName = useProfileNames();
  const idm = useIdmClient();

  const doSwitch = (profile: Profile) => {
    switchProfile(profile.id);
  };

  useActiveMenuItem("settings-personal");

  useEffect(() => {
    if (props.registerOrganization == true) {
      openDialog("registerOrganization");
    }
  }, [openDialog, props.registerOrganization]);

  const [createProfileCall, createProfileState] = useSafeSistaAsync(
    idm.createLoggedAccountProfile
  );

  const doCreateProfile = useCallback(
    async (pt: ProfileType) => {
      const yes = await confirm({
        question: t(
          "profile.createConfirmation",
          "Chcete opravdu vytvořit nový profil?"
        ),
      });
      if (yes) {
        const result = await createProfileCall({ value: pt });
        if (typeof result === "object" && result) {
          showSuccess(t("ucet.profile.created", "Profil byl vytvořen"));
          refresh();
        }
      }
    },
    [confirm, createProfileCall, refresh, t, showSuccess]
  );

  const hasExpert = loggedAccount.profiles.some(
    (value) => value.type === "EXPERT"
  );

  const hasApplicant = loggedAccount.profiles.some(
    (value) => value.type === "APPLICANT"
  );

  const profiles = loggedAccount.profiles.filter((p) => p.type !== "ISTA");

  const headerConfig: DistaPageHeaderProps = {
    breadcrumbs: [
      {
        title: t("ucet.menu.breadcrumbs.home", "Domů"),
        action: () => (window.location.href = "/ucet/"),
      },
      {
        title: t("ucet.settings.profiles.title", "Uživatelské profily"),
      },
    ],
    pageTitle: t("ucet.settings.profiles.title", "Uživatelské profily"),
    chipsDefinition: [
      //{label: t('chips.approved', 'Approved'), color: 'primary'},
    ],
    actions: !hasExpert
      ? [
          {
            title:
              t("ucet.profile.createNewOfType", "Založit nový profil typu") +
              " " +
              profileName("EXPERT"),
            action: () => doCreateProfile("EXPERT"),
            primary: true,
          },
          {
            title:
              t("ucet.profile.createNewOfType", "Založit nový profil typu") +
              " " +
              profileName("APPLICANT"),
            action: () => doCreateProfile("APPLICANT"),
            primary: false,
          },
        ]
      : [
          {
            title:
              t("ucet.profile.createNewOfType", "Založit nový profil typu") +
              " " +
              profileName("APPLICANT"),
            action: () => doCreateProfile("APPLICANT"),
            primary: true,
          },
        ],
    // {
    //     title: t('mainpage.actions.primary', 'Primary action'),
    //     action: () => alert('Primary action clicked'),
    //     primary: true,
    // },
    // {title: t('mainpage.actions.action1', 'First action'), action: () => alert('First action clicked')},
    // {title: t('mainpage.actions.action2', 'Second action'), action: () => alert('Second action clicked')},
  };

  const localNavigationConfig = useLocalNavigation();

  return (
    <SistaPageLayout localNavigationConfig={localNavigationConfig}>
      <DistaContent headerConfig={headerConfig} fullWidth={true}>
        <Box margin={2}>
          <Typography variant="body1" sx={{ my: 2 }}>
            {t(
              "ucet.settings.profiles.descrition",
              "U vašeho účtu evidujeme následující uživatelské profily. Mezi těmito profile lze přepínat a zastávat tak v SISTA různé role (expert, gestor, poskytovatel, ...). S každým profilem se pojí jiná agenda a je proto možné si ke každému profilu nastavit i jiné kontaktní údaje, které se pak v rámci SISTA budou pro příslušnou agendu využívat."
            )}
          </Typography>
          <Stack direction={"column"} sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {profiles.map((profile) => {
                  return (
                    <Box key={profile.id} marginBottom={2}>
                      <ProfileCard
                        profile={profile}
                        editable={true}
                        onProfileSelected={doSwitch}
                      />
                    </Box>
                  );
                })}
                {/* <Right sx={{ mt: 4 }}>
                {!hasExpert && (
                  <SmartButton
                    sx={{ ml: 2 }}
                    onClick={() => doCreateProfile("EXPERT")}
                  >
                    {t(
                      "ucet.profile.createNewOfType",
                      "Založit nový profil typu"
                    )}
                    &nbsp;
                    {profileName("EXPERT")}
                  </SmartButton>
                )}
                {!hasApplicant && (
                  <SmartButton
                    sx={{ ml: 2 }}
                    onClick={() => doCreateProfile("APPLICANT")}
                  >
                    {t(
                      "ucet.profile.createNewOfType",
                      "Založit nový profil typu"
                    )}
                    &nbsp;
                    {profileName("APPLICANT")}
                  </SmartButton>
                )}
              </Right> */}
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </DistaContent>
    </SistaPageLayout>
  );
};
