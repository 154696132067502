import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { AlertsContext, DistaInfoListBlock } from "@sista/dista-ui";
import { IdmClientContext } from "@sista/idm-client";
import {
  Organization,
  OrganizationRegistrationRequestDTO,
  SistaId,
} from "@sista/library-stasi";
import { SistaFistoSingle, SistaFistoView } from "@sista/service-fisto-ui";
import { SistaSpravaUkoluList } from "@sista/service-workflow-ui";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  useDelegateOptions,
  useDeliveryOptions,
} from "./OrganizationCreateDelegateContent";
import { OrganizationInfoContent } from "./OrganizationInfoContent";

export type OrganizationFindForm = {
  idNumber?: string;
};

export type OrganizationRegisterApproveViewProps = {
  organization?: Organization;
  registration?: OrganizationRegistrationRequestDTO;
};

export const OrganizationRegisterApproveView = (
  props: OrganizationRegisterApproveViewProps
) => {
  const alerts = useContext(AlertsContext);
  const { t } = useTranslation("ucet");
  const navigate = useNavigate();
  const ctx = useContext(IdmClientContext);

  const delegateOptions = useDelegateOptions();
  const deliveryOptions = useDeliveryOptions();

  const deliery = props.registration?.deliveryMethod
    ? deliveryOptions.find(
        (option) => option.id === props.registration.deliveryMethod
      )?.label
    : "-";

  const delegate = props.registration?.representation
    ? delegateOptions.find(
        (option) => option.id === props.registration.representation
      )?.label
    : "-";

  const taskContext = useMemo(() => {
    if (props.registration?.requestId == null) return [];
    if (props.organization?.id == null) return [];
    return [
      new SistaId("organizations", props.organization.id).asUrn(),
      new SistaId("workflows", "organization_registration").asUrn(),
      new SistaId(
        "organizationRegistration",
        props.registration?.requestId
      ).asUrn(),
    ];
  }, [props.organization.id, props.registration?.requestId]);

  const attachmentContext = useMemo(() => {
    if (props.registration?.requestId == null) return [];
    if (props.organization?.id == null) return [];
    return [
      props.registration.profileUrn,
      new SistaId("organizations", props.organization.id).asUrn(),
      new SistaId(
        "organizationRegistration",
        props.registration?.requestId
      ).asUrn(),
    ];
  }, [
    props.organization.id,
    props.registration.profileUrn,
    props.registration?.requestId,
  ]);

  if (ctx == null) return <CircularProgress />;

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Grid container>
          <Grid item sm={6}>
            <OrganizationInfoContent organization={props.organization} />
            <Divider />
            <DistaInfoListBlock
              label={t(
                "ucet.organization.delegate.requestTitle",
                "Požadavek na verifikaci"
              )}
              infoListItems={[
                {
                  primaryColumn: t(
                    "ucet.organization.delegate.request.id",
                    "ID žádosti"
                  ),
                  secondaryColumn: (
                    <strong>{props.registration.requestId}</strong>
                  ),
                },
                {
                  primaryColumn: t(
                    "ucet.organization.delegate.request.deliveryMethod",
                    "Způsob doručení"
                  ),
                  secondaryColumn: deliery,
                },
                {
                  primaryColumn: t(
                    "ucet.organization.delegate.request.representation",
                    "Forma zastoupení"
                  ),
                  secondaryColumn: delegate,
                },
                {
                  primaryColumn: t(
                    "ucet.organization.delegate.request.registrationFileId",
                    "Připravené podání "
                  ),
                  secondaryColumn: props.registration.registrationFileId ? (
                    <SistaFistoSingle
                      authKey={ctx.authToken}
                      variant="link"
                      fileId={props.registration.registrationFileId}
                    />
                  ) : (
                    "-"
                  ),
                },
                {
                  primaryColumn: t(
                    "ucet.organization.delegate.request.signedFileId",
                    "Podepsané podání"
                  ),
                  secondaryColumn: props.registration.signedFileId ? (
                    <SistaFistoSingle
                      authKey={ctx.authToken}
                      variant="link"
                      fileId={props.registration.signedFileId}
                    />
                  ) : (
                    "-"
                  ),
                },
              ]}
            ></DistaInfoListBlock>
            <Divider />
            <Stack direction={"column"} spacing={2} sx={{ ml: 1, mt: 2 }}>
              <Typography variant="h5">
                {t("ucet.organization.delegate.request.attachment", "Přílohy")}
              </Typography>

              <SistaFistoView
                authKey={ctx.authToken}
                category={"organizationMeta"}
                context={attachmentContext}
                deleteDisabled={true}
              />
            </Stack>
          </Grid>
          <Grid item sm={6} pl={2}>
            <SistaSpravaUkoluList
              loggedAuthKey={ctx.authToken}
              tasksContext={taskContext}
              spravaUkoluRoot={"/sprava-ukolu"}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};
